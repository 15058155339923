.dl {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.dl_wrapper {
  background: #fff;
  color: #7a1d05;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .dl_spinner {
    height: 10vw;
    width: 10vw;
    animation: rotate 1.4s infinite linear;
    border: 8px solid #7a1d05;
    border-right-color: transparent;
    border-radius: 50%;
    margin: 3vh 0;
  }
}
@media only screen and (min-width: 768px) {
  .dl_spinner {
    height: 4vw;
    width: 4vw;
    animation: rotate 1.4s infinite linear;
    border: 8px solid #7a1d05;
    border-right-color: transparent;
    border-radius: 50%;
    margin: 3vh 0;
  }
}
.dl_cp {
  font-size: 5vw;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}
.dl_dawin {
  font-size: 13vw;
  font-weight: 600;
  margin-bottom: 5px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disappear {
  animation: fade-out 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
