.dl {
  z-index: 10000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.dl_wrapper {
  color: #7a1d05;
  width: 100%;
  height: 100%;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .dl_spinner {
    height: 10vw;
    width: 10vw;
    border: 8px solid #7a1d05;
    border-right-color: #0000;
    border-radius: 50%;
    margin: 3vh 0;
    animation: 1.4s linear infinite rotate;
  }
}

@media only screen and (min-width: 768px) {
  .dl_spinner {
    height: 4vw;
    width: 4vw;
    border: 8px solid #7a1d05;
    border-right-color: #0000;
    border-radius: 50%;
    margin: 3vh 0;
    animation: 1.4s linear infinite rotate;
  }
}

.dl_cp {
  text-align: center;
  font-size: 5vw;
  font-style: italic;
  font-weight: 400;
}

.dl_dawin {
  margin-bottom: 5px;
  font-size: 13vw;
  font-weight: 600;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.disappear {
  animation: .5s forwards fade-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
}

/*# sourceMappingURL=index.a51b3289.css.map */
